// Style
import './style.scss'

const List = ({ data }) => {
  return (
    <ul className="list">
      {data.map(({ item }, key) => (
        <li key={key}>{item}</li>
      ))}
    </ul>
  )
}

export default List

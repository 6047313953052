import { Helmet } from "react-helmet"

// Components
import Header from './components/organisms/Header'
import Opening from './components/organisms/Opening'
import Assortiment from "./components/organisms/Assortiment"
import Uitgangspunten from "./components/organisms/Uitgangspunten"
import Products from "./components/organisms/Products"
import Devider from "./components/organisms/Devider"
import Footer from './components/organisms/Footer'

// Style
import './App.scss'

const App = () => {
  return (
    <>
      <Helmet>
        <title>Bakkerij De Tureluur</title>
        <meta name="description" content="Bakkerij De Tureluur. Bakkerij in het Oosterwold" />
        <html lang="nl-nl" amp />
      </Helmet>
      <main>
        <div className="main">
          <Header />
          <Opening />
          {/* <div className="product-overview"> */}
            <Uitgangspunten />
            <Products />
          {/* </div> */}
          <Assortiment />
          <Devider />
        </div>
        <Footer />
      </main>
    </>
  )
}

export default App

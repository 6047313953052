import { Img } from 'react-image'

// Style
import './style.scss'

// Assets
import logo from '../../images/logo.png'

const Footer = () => {
	return (
		<footer className='footer'>
			<div className='container'>
				<div className='wrapper'>
					<article>
						<h4>Contact</h4>
						<span>Sandra Ansmink</span>
						<span>Ezra Dopico Peña</span>
					</article>
					<article>
						<h6>Adress</h6>
						<span>Tureluurdwarsstraat 12</span>
						<span>1349 EK Almere</span>
					</article>
					<article>
						<h6>Socials</h6>
						<span>
							<a href="mailto:detureluur@gmail.com">detureluur@gmail.com</a>
						</span>
						<span>
							<a href="https://www.instagram.com/bakkerij_detureluur/" target="_blank" rel="noreferrer">Instagram</a>
						</span>
					</article>
					<article>
						<h6>Downloads</h6>
						<span>
							<a href="../assets/stadstuin-raasdonder.pdf" download>Download de Raasdonder folder</a>
						</span>
					</article>
					<article>
						<h6>Links</h6>
						<span>
							<a href="https://www.omroepflevoland.nl/nieuws/186510/warme-familiebroodjes-in-oosterwold" target="_blank" rel="noreferrer">Warme familiebroodjes in Oosterwold</a>
						</span>
						<span>
							<a href="https://www.juliusjaspers.nl/bakkerij-de-tureluur/" target="_blank" rel="noreferrer">Julius Jaspers over de bakkerij</a>
						</span>
					</article>
				</div>
				<div className='wrapper'>
					<ul>
						<h4>Bestellingen</h4>
						<li>
							Bestellingen graag via de mail.
						</li>
						<li>
							Houd er rekening mee dat er een aantal dagen voorbereiding nodig is voor veel van onze producten.
						</li>
						<li>
							<Img src={logo} className='logo' />
							Duurzaam en puur
						</li>
					</ul>
				</div>
			</div>
		</footer>
	)
}

export default Footer

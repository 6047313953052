import { Img } from 'react-image'

// Assets
import image from '../../images/devider.jpg'

// Styling
import './style.scss'

const Devider = () => {
  return (
    <section className='devider'>
      <div className='wrapper'>
        <article>
          <h2>Openingstijden:</h2>
          <ul>
            {/* <li>Woensdag 8.00 - 17.00</li> */}
            <li>Zaterdag 9.00 - 14.00</li>
            <li>Zondag 9.00 – 14.00</li>
            {/* <li>Donderdag (oneven weken) 16.30 – 20.00 pizza’s op bestelling</li> */}
          </ul>
        </article>
        <Img src={image} />
      </div>
    </section>
  )
}

export default Devider

import { Img } from 'react-image'

// Assets
import opening from '../../images/opening.jpg'

// Style
import './style.scss'

const Opening = () => (
  <section className="opening">
    <figure>
      <figcaption>
        <h2>Voor mooi en eerlijk brood met karakter.</h2>
        <p>De bakkerij opende op 11 mei 2019 voor het eerst haar deur. Dat was weliswaar de deur van ons tijdelijke pand, maar toch. Het gaf ons de kans een jaar met het Oosterwold te groeien, vraag en aanbod af te tasten en kennis te maken met een fantastische klantenkring. Wat voelen wij ons bevoorrecht!</p>
        <p>Na bijna een jaar hebben we het definitieve pand betrokken, nee niet alles is af, maar het is werkbaar. Een zee van ruimte. We kunnen de productie opschalen door meer ruimte, en qua bedrijfsmiddelen breiden we stap voor stap uit.</p>
      </figcaption>
      <Img src={opening} />
    </figure>
  </section>
)

export default Opening

import { useState } from 'react'

// Components
import Button from '../../atoms/Button'

// Styles
import './style.scss'

const Products = () => {
  const [activeProduct, setActiveProduct] = useState(0)

  const buttons = [
    {
      id: 0,
      title: "Bio en puur desem"
    },
    {
      id: 1,
      title: "Bio viennoiserie"
    }
  ]

  const products = [
    {
      id: 0,
      title: "Bio en puur desem",
      description: [
        "Robuuste desembroden van diverse graansoorten en baguettes vormen de basis van het assortiment. Zo is er de lemaire, een mooie batard van gebuild tarwemeel (=lemaire) op basis van een volkoren-roggedesem. De lemaire is er tevens in een gevulde variant. Het tarwe volkorenbrood, een batard waar we zonnebloempitten of pompoenpitten aan toevoegen, heeft ook een roggedesem als basis en overigens een deel speltmeel. Hiermee is het eigenlijk een meergranenbrood. Het zijn karaktervolle broden op de stenen vloer gebakken. Ook speltbrood in diverse varianten, meer of minder volkoren en al dan niet met sesam- of maanzaad behoren tot het vaste assortiment. Uiteraard volledig op desembasis.",
        "In aanvulling op het vaste assortiment desembrood is er een variatie aan andere broden afhankelijk van seizoen en beschikbaarheid van producten. In alle broden zit maximaal 2% Keltisch zeezout."
      ]
    },
    {
      id: 1,
      title: "Bio viennoiserie",
      description: [
        "Er zijn regelmatig brioches, al dan niet met een vulling van gekonfijte vruchten, chocolade en ‘raw cacao’, vloerkadetjes, appel-rozijnenbolletjes, kwarkbolletjes, Italiaanse bollen, groentebroodjes etc. Voor dit type brood gebruiken we soms behalve desem ook gist of een voordeeg.",
        "Ook hebben we croissants, deze zijn beschikbaar in allerlei variaties, onder andere met kaas of als Danish met vulling van frangipane en vruchten."
      ]
    }
  ]

  return (
    <section className='products'>
      <div className='wrapper'>
        <h2>De producten</h2>
        <div className='button-group'>
          {buttons.map(({ id, title }, idx) => (
            <Button
              title={title}
              key={idx}
              onClick={() => setActiveProduct(id)}
              className={id === activeProduct ? 'is-active' : ''}
            />
          ))}
        </div>
        <article>
          {products.map(({ title, description, id }, key) => (
            <div 
              key={key}
              style={{
                display: activeProduct === id ? 'block' : 'none' 
              }}
            >
              <h4>{title}</h4>
              {description.map((item, key) => {
                if (key === 1) {
                  return (
                    <p key={key}>
                      <br />
                      {item}
                    </p>
                  )
                }

                return (
                  <p key={key}>
                    {item}
                  </p>
                )
              })}
            </div>
          ))}
        </article>
      </div>
    </section>
  )
}

export default Products

import { useState } from 'react'

// Components
import Button from '../../atoms/Button'

// Style
import './style.scss'

const Assortiment = () => {
  const [activeButton, setActiveButton] = useState(0);

  const data = [
    {
      name: 'Lemaire/Grijs brood',
      ingredients: 'Gebuild tarwemeel/ lemaire en roggedesem',
      weight: ['½ kg', '1 kg'],
      price: ['€2,80', '€4,50']
    },
    {
      name: 'Gevulde lemaire',
      ingredients: 'Gebuild tarwemeel/ lemaire, tarwedesem, abrikozen, walnoten en hazelnoten',
      weight: ['580 g'],
      price: ['€4,50']
    },
    {
      name: 'Tarwe volkoren (meergranen)',
      ingredients: 'Tarwemeel, speltmeel, pitjes en roggedesem',
      weight: ['600 g', '900 g'],
      price: ['€3,50', '€5,-']
    },
    {
      name: 'Volkoren rozijnen',
      ingredients: 'Tarwemeel, tarwedesem en rozijnen',
      weight: ['500 g'],
      price: ['€3,60']
    },
    {
      name: 'Polderlandbrood grijs',
      ingredients: 'Lokaal steengemalen tarwebloem en tarwedesem',
      weight: ['800 g'],
      price: ['€3,80']
    },
    {
      name: 'Spelt wit',
      ingredients: 'Speltbloem, (rogge), tarwedesem met/zonder maanzaad/sesamzaad',
      weight: ['600 g'],
      price: ['€3,50', '€3,70']
    },
    {
      name: 'Spelt bruin',
      ingredients: 'Speltmeel- en bloem, tarwedesem met/zonder maanzaad/sesamzaad',
      weight: ['600 g'],
      price: ['€3,50', '€3,70']
    },
    {
      name: 'Baguette',
      ingredients: 'Tarwebloem, tarwedesem, (soms rogge/durum/kamut) eventueel zaden en pitten',
      weight: ['350 g'],
      price: ['€1,80', '€2,20']
    },
    {
      name: 'Baguette XL',
      ingredients: 'Tarwebloem, tarwedesem, (soms rogge/durum/kamut) eventueel zaden en pitten',
      weight: ['500 g'],
      price: ['€2,50', '€2,70']
    },
    {
      name: 'Ciabatta/ Focacccia/ Italiaanse bol',
      ingredients: 'Tarwebloem, (durum), (speltbloem), tarwedesem. olijfolie, soms vulling gedroogde tomaat, olijven en kruiden',
      weight: ['Divers'],
      price: ['Vanaf €1,20']
    },
    {
      name: 'Pain de Seigle',
      ingredients: 'Roggemeel, roggedesem, wisselende vulling. Bijvoorbeeld walnoten, oude kaas of notenmix',
      weight: ['600 g'],
      price: ['€4,50']
    },
    {
      name: 'Tureluurtje',
      ingredients: 'Gebuild tarwemeel en bloem, tarwedesem, roggemeel, port, krenten, walnoten en rocquefort',
      weight: ['250 g'],
      price: ['€3,80']
    },
    {
      name: 'Vloerkadetje',
      ingredients: 'Tarwebloem, tarwedesem, gist, melk, suiker, ei, boter en rijstmeel',
      weight: ['80 g'],
      price: ['€0,80']
    },
    {
      name: 'Appel-Rozijnenbolletje',
      ingredients: 'Tarwebloem en gebuild meel, gist, melk, suiker, ei, boter, appel, rozijnen en/of krenten',
      weight: ['80 - 90 g'],
      price: ['€0,90']
    },
    {
      name: 'Haverbol',
      ingredients: 'Tarwe- meel en bloem, tarwedesem, roggemeel en havervlokken',
      weight: ['500 g'],
      price: ['€3,60']
    },
    {
      name: 'Brioche',
      ingredients: 'Tarwebloem, suiker, honing, tarwedesem, gist, melk, boter en ei',
      weight: ['100 g'],
      price: ['€1,20']
    },
    {
      name: 'Brioche gevuld',
      ingredients: 'Tarwebloem, suiker, honing, tarwedesem, gist, melk, boter, ei, chocolade en citrus',
      weight: ['100 g'],
      price: ['€1,50']
    },
    {
      name: 'Croissant',
      ingredients: 'Tarwebloem, ei, boter, melk, suiker, tarwedesem en gist',
      weight: ['90 g'],
      price: ['€1,50']
    },
  ]

  return (
    <section className='assortiment'>
      <div className='wrapper'>
        <h2>Overzicht assortiment</h2>
        <ul>
          {data.map(({
            name,
            ingredients,
            weight,
            price
          }, key) => (
            <li key={key}>
              <Button
                onClick={() => setActiveButton(key)}
                title={name}
                className={activeButton === key ? 'is-active' : ''}
                variation='secondary'
              />
              <span className={`${activeButton === key ? 'is-active' : ''} info`}>
                <span className='info-item'>
                  <h4>ingredienten:</h4>
                  <p>{ingredients}</p>
                </span>
                <span className='info-item'>
                  <h4>Gewicht:</h4>
                  {weight.map((item, key) => (
                    <p key={key}>{item}</p>
                  ))}
                </span>
                <span key={key} className='info-item'>
                  <h4>Prijs:</h4>
                  {price.map((item, key) => (
                    <p key={key}>{item}</p>
                  ))}
                </span>
              </span>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default Assortiment

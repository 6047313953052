import { Img } from 'react-image'

// Components
import List from '../../atoms/List'

// Style
import './style.scss'

// Assets
import uitgangspunten from '../../images/uitgangspunten.jpg'

const data = [
  {
    item: 'Pure grondstoffen gebruiken, van biologische of biologisch dynamische oorsprong en zo regionaal mogelijk geproduceerd'
  },
  {
    item: 'De meeste van onze degen bereiden met desem'
  },
  {
    item: 'Geen onnodige toevoegingen of meelverbeteraars gebruiken'
  },
  {
    item: 'Het deeg ruim tijd geven te fermenteren, veelal overnacht zodat het makkelijk verteerbaar is en alle tijd heeft optimaal smaak en aroma te ontwikkelen'
  },
  {
    item: 'Ons deeg handmatig verwerken'
  },
  {
    item: 'Al onze producten volledig zelf maken, dus geen halffabricaten inkopen'
  },
  {
    item: 'Al onze producten vers aanbieden tenzij anders vermeld'
  },
  {
    item: 'Zo mogelijk producten van eigen erf/Oosterwold benutten, denk aan fruit, kruiden, groenten en eieren'
  }
]

const Uitgangspunten = () => (
  <section className="uitgangspunten">
    <figure>
      <div className='image-wrapper'>
        <Img src={uitgangspunten} />
      </div>
      <figcaption>
        <h2>Dit zijn onze uitgangspunten:</h2>
        <List data={data} className='uitgangspunten-list' />
      </figcaption>
    </figure>
  </section>
)

export default Uitgangspunten

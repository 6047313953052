import { Img } from 'react-image'

// Assets
import logo from '../../images/logo.png'
import banner from '../../images/banner.jpg'

// Style
import './style.scss'

const Header = () => {
  return (
    <section className='header'>
      <figure>
        <figcaption>
          <Img src={logo} className='logo' />
          <div className='title'>
            <h1>Bakkerij de Tureluur</h1>
            <h3>EST 2019</h3>
            <h2>De eerste bakkerij in het Oosterwold!</h2>
          </div>
        </figcaption>
        <Img src={banner} alt='Placeholder alt' />
      </figure>
    </section>
  )
}

export default Header
